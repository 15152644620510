<template>
  <router-view class="non-router-view" />
</template>

<script>

export default {
  name: "index"
};
</script>

<style lang="scss" scoped>
/*切换样式出错处理*/
.non-router-view {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  // background-color: #F2F2F2 ;
  max-width: 660px;
  -webkit-overflow-scrolling: touch;
}
</style>
